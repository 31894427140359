export default function isPointInRect(
  pointX: number,
  pointY: number,
  left: number,
  top: number,
  width: number,
  height: number,
) {
  return (
    pointX >= left &&
    pointX < left + width &&
    pointY >= top &&
    pointY < top + height
  );
}
