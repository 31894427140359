import "./Button.scss";

import React, { ReactNode, useCallback, useState } from "react";

import usePointerUpInside from "./hooks/usePointerUpInside";

export interface ButtonProps {
  variant: "primary" | "secondary" | "tertiary";
  size: "regular" | "small";
  onActivate: () => void;
  onStatusDidChange?: (status: "inactive" | "hover" | "active") => void;
  glyph?: ReactNode;
  disabled?: boolean;
  children: string;
}

export default function Button(props: ButtonProps) {
  const [isActive, setActive] = useState(false);
  const { onStatusDidChange, onActivate, disabled } = props;

  const localStatusDidChange = useCallback(
    (status: "inactive" | "hover" | "active") => {
      setActive(status === "active");
      if (onStatusDidChange) {
        onStatusDidChange(status);
      }
    },
    [onStatusDidChange],
  );

  const localOnActivate = useCallback(() => {
    if (!disabled) {
      onActivate();
    }
  }, [disabled, onActivate]);

  return (
    <button
      className={`Button ${props.variant} ${props.size} ${
        props.disabled ? "Disabled" : ""
      } ${isActive ? "Active" : ""}`}
      touch-action="manipulation"
      {...usePointerUpInside(localOnActivate, localStatusDidChange)}
      type="button"
    >
      {props.glyph || null}
      {props.children}
    </button>
  );
}
