import React, { useCallback, useRef, useState } from "react";

import isPointInRect from "../../util/isPointInRect";

export default function usePointerUpInside(
  onActivate: () => void,
  onStatusDidChange?: (status: "inactive" | "hover" | "active") => void,
) {
  const [hasPointerEvents, setHasPointerEvents] = useState(
    window.PointerEvent !== undefined,
  );

  type PointerState = {
    initialScreenX: number;
    initialScreenY: number;
    isViable: boolean;
    isActive: boolean;
    initialTarget: Element;
  };
  const pointerStateMap = useRef<Map<number, PointerState>>(new Map());
  const removeDocumentListenersRef = useRef<(() => void) | null>(null);
  function eventIsInTarget(
    event: PointerEvent | React.PointerEvent,
    target: Element,
  ): boolean {
    const boundingBox = target.getBoundingClientRect();
    return isPointInRect(
      event.clientX,
      event.clientY,
      boundingBox.left,
      boundingBox.top,
      boundingBox.width,
      boundingBox.height,
    );
  }
  function isAnyCurrentPointerViable() {
    return (
      [...pointerStateMap.current.values()].filter(state => state.isViable)
        .length > 0
    );
  }
  function cleanUpDocumentListenersIfIdle() {
    if (
      pointerStateMap.current.size === 0 &&
      removeDocumentListenersRef.current
    ) {
      removeDocumentListenersRef.current();
      removeDocumentListenersRef.current = null;
    }
  }
  const onPointerMove = useCallback(
    (event: PointerEvent) => {
      const pointerState = pointerStateMap.current.get(event.pointerId);
      if (
        pointerState &&
        pointerState.isViable &&
        pointerState.isActive &&
        onStatusDidChange
      ) {
        const isInsideTarget = eventIsInTarget(
          event,
          pointerState.initialTarget,
        );
        onStatusDidChange(isInsideTarget ? "active" : "inactive");
      }
    },
    [onStatusDidChange],
  );
  const onPointerUp = useCallback(
    (event: PointerEvent) => {
      const pointerState = pointerStateMap.current.get(event.pointerId);
      if (
        pointerState &&
        pointerState.isViable &&
        eventIsInTarget(event, pointerState.initialTarget)
      ) {
        onActivate();
      }
      pointerStateMap.current.delete(event.pointerId);
      cleanUpDocumentListenersIfIdle();
    },
    [onActivate],
  );
  const onPointerCancel = useCallback((event: PointerEvent) => {
    pointerStateMap.current.delete(event.pointerId);
    cleanUpDocumentListenersIfIdle();
  }, []);
  const onPointerDown = useCallback(
    (event: React.PointerEvent) => {
      const isViable =
        !isAnyCurrentPointerViable() &&
        (event.pointerType !== "mouse" || (event.buttons & 1) === 1);
      pointerStateMap.current.set(event.pointerId, {
        initialScreenX: event.screenX,
        initialScreenY: event.screenY,
        isViable,
        isActive: true,
        initialTarget: event.currentTarget,
      });
      if (onStatusDidChange && isViable) {
        event.currentTarget.setPointerCapture(event.pointerId);
        onStatusDidChange("active");
      }
      if (!removeDocumentListenersRef.current) {
        document.addEventListener("pointerup", onPointerUp);
        document.addEventListener("pointercancel", onPointerCancel);
        if (onStatusDidChange) {
          document.addEventListener("pointermove", onPointerMove);
        }
        removeDocumentListenersRef.current = () => {
          document.removeEventListener("pointerup", onPointerUp);
          document.removeEventListener("pointercancel", onPointerCancel);
          if (onStatusDidChange) {
            document.removeEventListener("pointermove", onPointerMove);
          }
        };
      }
    },
    [onPointerUp, onPointerCancel, onPointerMove, onStatusDidChange],
  );
  const onPointerEnter = useCallback(
    (event: React.PointerEvent) => {
      if (!isAnyCurrentPointerViable() && onStatusDidChange) {
        onStatusDidChange("hover");
      }
    },
    [onStatusDidChange],
  );
  const onPointerLeave = useCallback(
    (event: React.PointerEvent) => {
      // A bit imprecise for some hypothetical device with both touch and mouse, but I don't really care.
      if (!isAnyCurrentPointerViable() && onStatusDidChange) {
        onStatusDidChange("inactive");
      }
    },
    [onStatusDidChange],
  );

  if (!hasPointerEvents) {
    import("pepjs").then(() => {
      setHasPointerEvents(true);
    });
    return {
      onPointerDown: () => {},
      onPointerEnter: () => {},
      onPointerLeave: () => {},
    };
  }

  return { onPointerDown, onPointerEnter, onPointerLeave };
}

if (window.PointerEvent === undefined) {
  import("pepjs");
}
