import React from "react";

const Svg15Px = props => (
  <svg width={15} height={15} viewBox="0 0 15 15" fill="none" {...props}>
    <path
      d="M5.00002 7.99998L7.00002 9.99998L10.5 5.5"
      fill="none"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Svg15Px;

