import "./FadeTransition.scss";

import React, { useMemo } from "react";
import { CSSTransition } from "react-transition-group";

export default function FadeTransition<P>(
  props: P & {
    delayMillis?: number;
    children?: React.ReactNode;
  },
) {
  const { delayMillis, children, ...otherProps } = props;

  const timeout = useMemo(
    () => ({
      enter: 150 + (delayMillis || 0),
      exit: 150 + (delayMillis || 0),
    }),
    [delayMillis],
  );

  return (
    <CSSTransition
      {...otherProps}
      classNames="FadeTransition"
      timeout={timeout}
      appear
    >
      {delayMillis ? (
        <div style={{ animationDelay: `${delayMillis}ms` }}>{children}</div>
      ) : (
        children
      )}
    </CSSTransition>
  );
}
