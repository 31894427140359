import { EssayProgress } from "./getProgressByEssay";

export default function getNextLevel(
  essayProgress: EssayProgress,
): number | null {
  let levelIndex = 0;
  while (essayProgress[levelIndex] !== undefined) {
    if (
      essayProgress[levelIndex].collectedCardCount <
      essayProgress[levelIndex].totalCardCount
    ) {
      return levelIndex;
    }
    levelIndex++;
  }
  return null;
}
