let state: "inactive" | "loading" | "loaded" = "inactive";

export function isScrollBehaviorPolyfillReady(): boolean {
  return (
    "scrollBehavior" in document.documentElement.style || state === "loaded"
  );
}

export function useScrollBehaviorPolyfill() {
  if (!isScrollBehaviorPolyfillReady() && state === "inactive") {
    state = "loading";
    console.info("Importing scroll behavior polfill");
    import("scroll-behavior-polyfill").then(() => {
      console.info("Done loading scroll behavior polfill");
      state = "loaded";
    });
  }
}
