import "./RadialProgressIndicator.scss";

import React, { useEffect, useRef, useState } from "react";

import usePrevious from "./hooks/usePrevious";

interface Props {
  progressFraction: number;
  displayValue: number;
  isFilled?: boolean;
  presentationStyle?: "default" | "dark";
}

const radius = 15.5;
const strokeWidth = 4;

export default React.memo(function RadialProgressIndicator(props: Props) {
  const centerRadius = radius - strokeWidth / 2;
  const circumference = centerRadius * 2 * Math.PI;

  const { progressFraction, displayValue, isFilled, presentationStyle } = props;
  const previousProgressFraction = usePrevious(progressFraction);

  const [didRecentlyChange, setDidRecentlyChange] = useState(false);
  const [incomingCounter, setIncomingCounter] = useState(0);
  const [outgoingCounter, setOutgoingCounter] = useState(0);

  const resetTimeoutHandle = useRef<((isUnmounting: boolean) => void) | null>(
    null,
  );

  useEffect(() => {
    if (
      previousProgressFraction !== undefined &&
      progressFraction !== previousProgressFraction
    ) {
      setDidRecentlyChange(true);
      setIncomingCounter(c => c + 1);

      const outgoingReset = setTimeout(
        () => setOutgoingCounter(c => c + 1),
        150,
      );

      if (resetTimeoutHandle.current) {
        resetTimeoutHandle.current(false);
      }
      const styleReset = setTimeout(() => {
        setDidRecentlyChange(false);
        setIncomingCounter(0);
        setOutgoingCounter(0);
      }, 2000);
      resetTimeoutHandle.current = (isUnmounting: boolean) => {
        clearTimeout(styleReset);
        if (isUnmounting) {
          clearTimeout(outgoingReset);
        }
      };
    }
  }, [progressFraction, previousProgressFraction]);

  useEffect(() => {
    return () => {
      if (resetTimeoutHandle.current) {
        resetTimeoutHandle.current(true);
        resetTimeoutHandle.current = null;
      }
    };
  }, []);

  function geometricSum(ratio: number, exponent: number) {
    return (1 - Math.pow(ratio, exponent)) / (1 - ratio);
  }

  const style: React.CSSProperties =
    incomingCounter > 0
      ? {
          transform: `scale(${1 +
            0.2 * geometricSum(0.96, incomingCounter) -
            0.16 * geometricSum(0.96, outgoingCounter)})`,
          transitionDuration:
            incomingCounter > outgoingCounter ? "150ms" : "400ms",
        }
      : {};

  return (
    <svg
      width={radius * 2 + 2}
      height={radius * 2 + 2}
      viewBox={`-1 -1 ${radius * 2 + 2} ${radius * 2 + 2}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`RadialProgressIndicator ${
        didRecentlyChange ? "DidRecentlyChange" : ""
      } ${presentationStyle === "dark" ? "Dark" : ""}`}
      style={style}
    >
      <circle
        className={`background ${isFilled ? "filled" : ""}`}
        cx={radius}
        cy={radius}
        r={centerRadius}
        strokeWidth={strokeWidth}
      />
      <circle
        className="progress"
        cx={radius}
        cy={radius}
        r={centerRadius}
        strokeWidth={strokeWidth}
        style={
          {
            "--circumference": circumference,
            strokeDashoffset:
              circumference * (1 - Math.min(1, progressFraction)),
          } as React.CSSProperties
        }
      />
      <text
        x="15.5"
        y={displayValue >= 100 ? "19" : "20"}
        textAnchor="middle"
        className={displayValue >= 100 ? "largeNumber" : undefined}
      >
        {displayValue}
      </text>
    </svg>
  );
});
