import "./EssayProgressIndicator.scss";

import React, { useLayoutEffect, useRef } from "react";

// Can't use SVGR here because we render this on the server for emails, and Webpack won't cooperate there (not sure why).
// To update SVGR output:
//    npx @svgr/cli --no-svgo 15px.svg > 15px.jsx
import Check from "../assets/check/15px";
import LongTermTarget from "../assets/long-term/24px";
import { EssayID } from "../essays/essayMetadata";
import {
  getIntervalLabelsForSchedule,
  SpacedRepetitionSchedule,
} from "../spacedRepetition";
import getNextLevel from "../util/getNextLevel";
import { ProgressByEssay } from "../util/getProgressByEssay";
import usePrevious from "./hooks/usePrevious";
import RadialProgressIndicator from "./RadialProgressIndicator";

const essayProgressIndicatorNodeLocations = {
  4: [
    [0, 289],
    [145, 234],
    [240, 110],
    [281, -46], // special-cased "long term"
  ],
  5: [
    [0, 289],
    [110, 257],
    [196, 180],
    [255, 75],
    [281, -46], // special-cased "long term"
  ],
  6: [
    [0, 289],
    [85, 267],
    [160, 220],
    [218, 148],
    [258, 67],
    [281, -46], // special-cased "long term"
  ],
};

const flatEssayProgressIndicatorNodeLocations = {
  4: [
    0,
    91,
    184,
    281, // special-cased "long term"
  ],
  5: [
    0,
    69,
    138,
    207,
    281, // special-cased "long term"
  ],
  6: [
    0,
    55,
    110,
    165,
    220,
    281, // special-cased "long term"
  ],
};

export function getEssayProgressIndicatorNodeLocations(
  schedule: SpacedRepetitionSchedule,
) {
  const length = getIntervalLabelsForSchedule(schedule).length;
  if (!(length === 4 || length === 5 || length === 6)) {
    throw new Error(`Unsupported schedule length ${length}`);
  }
  return {
    exponential: essayProgressIndicatorNodeLocations[length],
    flat: flatEssayProgressIndicatorNodeLocations[length],
  };
}

export const essayProgressIndicatorNodeWidth = 58;
export const essayProgressIndicatorNodeHeight = 68;

interface Props {
  progress: ProgressByEssay[EssayID] | null;
  form: "flat" | "exponential";
  presentationStyle?: "default" | "dark";
  schedule: SpacedRepetitionSchedule;
}

export default React.memo(
  React.forwardRef(function EssayProgressIndicator(
    props: Props,
    ref: React.Ref<HTMLDivElement>,
  ) {
    const { progress, form, presentationStyle, schedule } = props;
    const labels = getIntervalLabelsForSchedule(schedule);
    const nextLevelIndex = progress ? getNextLevel(progress) : null;

    const {
      exponential: exponentialNodeLocations,
      flat: flatNodeLocations,
    } = getEssayProgressIndicatorNodeLocations(schedule);

    return (
      <div
        className={`EssayProgressIndicator ${form} ${
          presentationStyle === "dark" ? "Dark" : ""
        }`}
        ref={ref}
      >
        <div className="Inner">
          <ExponentialProgressLine form={form} />
          <div className="Indicators">
            {labels.map((label, i) => {
              let indicatorState: IndicatorProps["state"];
              if (progress) {
                const { collectedCardCount, totalCardCount } = progress[i];
                if (collectedCardCount > 0 || i === nextLevelIndex) {
                  if (collectedCardCount >= totalCardCount) {
                    indicatorState = { status: "complete" };
                  } else {
                    indicatorState = {
                      status: "started",
                      progressFraction: collectedCardCount / totalCardCount,
                      displayValue: collectedCardCount,
                      isNextLevel: i === nextLevelIndex,
                    };
                  }
                } else {
                  indicatorState = { status: "unstarted" };
                }
              } else {
                indicatorState = { status: "unstarted" };
              }
              return (
                <Indicator
                  label={label}
                  key={i}
                  isLongTerm={i === labels.length - 1}
                  state={indicatorState}
                  x={
                    form === "exponential"
                      ? exponentialNodeLocations[i][0]
                      : flatNodeLocations[i]
                  }
                  y={
                    form === "exponential"
                      ? exponentialNodeLocations[i][1]
                      : -25
                  }
                  presentationStyle={presentationStyle || "default"}
                />
              );
            })}
          </div>
        </div>
      </div>
    );
  }),
);

interface IndicatorProps {
  label: string;
  isLongTerm: boolean;
  x: number;
  y: number;
  state:
    | {
        status: "started";
        progressFraction: number;
        displayValue: number;
        isNextLevel: boolean;
      }
    | { status: "unstarted" }
    | { status: "complete" };
  presentationStyle: "default" | "dark";
}

const Indicator = React.memo(function Indicator(props: IndicatorProps) {
  const { label, state, isLongTerm, x, y, presentationStyle } = props;

  let pointContents: React.ReactNode;
  if (state.status === "started") {
    const { progressFraction, displayValue } = state;
    pointContents = (
      <RadialProgressIndicator
        progressFraction={progressFraction}
        displayValue={displayValue}
        isFilled
        presentationStyle={presentationStyle}
      />
    );
  } else if (state.status === "complete") {
    pointContents = <Check />;
  } else if (isLongTerm) {
    pointContents = <LongTermTarget />;
  } else {
    pointContents = <div className="Unstarted" />;
  }

  const isNextLevel = "isNextLevel" in state && state.isNextLevel;

  return (
    <div
      className={`Indicator ${state.status} ${isLongTerm ? "LongTerm" : ""}`}
      style={{ left: x, top: y }}
    >
      <span className="Label" title={label}>
        {label}
      </span>
      <div className="Point">{pointContents}</div>
      {isNextLevel && (
        <span className="NextLevel" title={label}>
          Next level
        </span>
      )}
    </div>
  );
});

const exponentialPathWidth = 280.5;
const flatPathWidth = 263.97;
const flatPathY = 4.90735;
function convertExponentialPathXToFlatPathX(x: number) {
  return (x * flatPathWidth) / exponentialPathWidth;
}

const exponentialPath =
  "M1 319.907C23.7624 315.535 36.4392 312.385 58.6 305.592C81.5207 298.567 95.2123 295.95 116.2 284.364C141.287 270.515 152.979 258.41 173 237.907C201.689 208.528 214.071 188.083 233.5 151.907C262.074 98.7052 273.171 64.7203 281.5 4.90735";

const flatPath = `M1 ${flatPathY}C${convertExponentialPathXToFlatPathX(
  23.7624,
)} ${flatPathY} ${convertExponentialPathXToFlatPathX(
  36.4392,
)} ${flatPathY} ${convertExponentialPathXToFlatPathX(
  58.6,
)} ${flatPathY}C${convertExponentialPathXToFlatPathX(
  81.5207,
)} ${flatPathY} ${convertExponentialPathXToFlatPathX(
  95.2123,
)} ${flatPathY} ${convertExponentialPathXToFlatPathX(
  116.2,
)} ${flatPathY}C${convertExponentialPathXToFlatPathX(
  141.287,
)} ${flatPathY} ${convertExponentialPathXToFlatPathX(
  152.979,
)} ${flatPathY} ${convertExponentialPathXToFlatPathX(
  173,
)} ${flatPathY}C${convertExponentialPathXToFlatPathX(
  201.689,
)} ${flatPathY} ${convertExponentialPathXToFlatPathX(
  214.071,
)} ${flatPathY} ${convertExponentialPathXToFlatPathX(
  233.5,
)} ${flatPathY}C${convertExponentialPathXToFlatPathX(
  262.074,
)} ${flatPathY} ${convertExponentialPathXToFlatPathX(
  273.171,
)} ${flatPathY} ${convertExponentialPathXToFlatPathX(281.5)} ${flatPathY}`;

const flatArrowX = 260.947;
const exponentialArrowX = 278;
const arrowWidth = 268 - flatArrowX;
const flatArrow = `M0 10L0 0L${arrowWidth} 5L0 10Z`;
const animationDuration = "1500ms";
const flatArrowTranslation = `${flatArrowX} 0`;
const flatArrowRotation = `0 ${arrowWidth / 2} 5`;
const flatArrowTransform = `translate(${flatArrowTranslation}) rotate(${flatArrowRotation})`;
const exponentialArrowTranslation = `${exponentialArrowX} 0`;
const exponentialArrowRotation = `-83 ${arrowWidth / 2} 5`;
const exponentialArrowTransform = `translate(${exponentialArrowTranslation}) rotate(${exponentialArrowRotation})`;
const spline = "0.19 1 0.22 1"; // duplication of $expoOut;

const ExponentialProgressLine = React.memo(
  function ExponentialProgressLine(props: { form: "flat" | "exponential" }) {
    const { form } = props;

    const pathAnimation = useRef<SVGAnimateElement | null>(null);
    const arrowTranslationAnimation = useRef<SVGAnimateElement | null>(null);
    const arrowRotationAnimation = useRef<SVGAnimateElement | null>(null);

    const previousForm = usePrevious(form);
    useLayoutEffect(() => {
      if (previousForm !== undefined && form !== previousForm) {
        try {
          for (const animation of [
            pathAnimation,
            arrowTranslationAnimation,
            arrowRotationAnimation,
          ]) {
            // TypeScript is missing this function...
            (animation.current! as any).beginElement();
          }
        } catch (e) {
          console.error(
            "Skipping animations (likely browser doesn't support)",
            e,
          );
        }
      }
    }, [previousForm, form]);

    return (
      <svg
        width="287"
        height="322"
        viewBox="0 0 287 322"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d={form === "exponential" ? exponentialPath : flatPath}
          fill="none"
          strokeWidth={form === "exponential" ? "3" : "2"}
        >
          <animate
            dur={animationDuration}
            attributeName="d"
            values={`${form === "exponential" ? flatPath : exponentialPath};${
              form === "exponential" ? exponentialPath : flatPath
            }`}
            calcMode="spline"
            fill="freeze"
            keySplines={spline}
            repeatCount={1}
            begin="indefinite"
            ref={pathAnimation}
          />
        </path>
        <g
          transform={
            form === "exponential"
              ? exponentialArrowTransform
              : flatArrowTransform
          }
        >
          <animateTransform
            attributeType="XML"
            attributeName="transform"
            type="translate"
            values={`${
              form === "exponential"
                ? flatArrowTranslation
                : exponentialArrowTranslation
            };${
              form === "exponential"
                ? exponentialArrowTranslation
                : flatArrowTranslation
            }`}
            dur="1250ms"
            calcMode="spline"
            keySplines={spline}
            repeatCount={1}
            begin="indefinite"
            ref={arrowTranslationAnimation}
          />
          <animateTransform
            attributeType="XML"
            attributeName="transform"
            type="rotate"
            values={`${
              form === "exponential"
                ? flatArrowRotation
                : exponentialArrowRotation
            };${
              form === "exponential"
                ? exponentialArrowRotation
                : flatArrowRotation
            }`}
            dur="1150ms"
            additive="sum"
            calcMode="spline"
            keySplines={spline}
            repeatCount={1}
            begin="indefinite"
            ref={arrowRotationAnimation}
          />
          <path d={flatArrow}></path>
        </g>
      </svg>
    );
  },
);
