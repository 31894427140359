import React from "react";

const Svg24Px = props => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <circle cx={12} cy={12} r={10.75} fill="none" strokeWidth={2.5} />
    <circle cx={12.0003} cy={12} r={5.95} fill="none" strokeWidth={2.5} />
    <circle cx={11.9996} cy={12} r={2.4} stroke="none" />
  </svg>
);

export default Svg24Px;

